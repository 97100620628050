export default function Selector({ setSelectedValue }) {
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
    };

    return (
        <div className="flex flex-col justify-center items-center ml-auto mr-auto">
            <label htmlFor="category">Choose a category:</label>

            <select
                name="category"
                id="category"
                onChange={handleChange}
                className="bg-purple-400 hover:bg-purple-500 text-white font-bold py-2 px-4 rounded-full my-2 items-center"
            >
                <option value="wildflowers">Wildflowers</option>
                <option value="beach">Beach</option>
                <option value="nature">Nature</option>
                <option value="social">Social</option>
                <option value="cooking">Cooking</option>
                <option value="wellness">Wellness</option>
                <option value="fashion">Fashion</option>
            </select>
        </div>
    );
}

import Button from "./components/GenerateButton";
import Selector from "./components/Selector";
import React, { useState } from "react";

export default function LandingPage() {
    const [selectedValue, setSelectedValue] = useState("");

    return (
        <div>
            <h2>test</h2>
            <Selector setSelectedValue={setSelectedValue}></Selector>
            <Button selectedValue={selectedValue}></Button>
        </div>
    );
}

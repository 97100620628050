import categories from "./categoryLists";
// const categories = require("./categoryLists.js");

export function getSentences(category, paragraphs) {
    let selectedCategory = categories[category];
    console.log(printParagraphs(selectedCategory, paragraphs));
    // const paragraph = sentenceBuilder(selectedCategory);
    // console.log(paragraph.join(" "));
}

function printParagraphs(selectedCategory, paragraphs) {
    let allCopy = [];
    for (let i = 0; i < paragraphs; i++) {
        allCopy.push(sentenceBuilder(selectedCategory));
    }
    return allCopy.join("\n\n");
}

function sentenceBuilder(selectedCategory) {
    let paragraph = [];
    for (let i = 0; i < Math.floor(Math.random() * (12 - 8) + 8); i++) {
        const sentenceLength = Math.floor(Math.random() * (12 - 5) + 5);
        let sentence = "";
        for (let j = 0; j < sentenceLength; j++) {
            sentence += selectedCategory.at(
                Math.floor(Math.random() * selectedCategory.length)
            );
            if (j !== sentenceLength - 1) {
                sentence += " ";
            }
        }
        sentence = sentence.charAt(0).toUpperCase() + sentence.slice(1);
        sentence += ".";
        paragraph.push(sentence);
    }
    return paragraph.join(" ");
    //first letter of sentence capitalized
    //period after last word
    //random number between 5 and 12 for sentence length?
}

// getSentences("wellness", 5);
// console.log(categories.wildflowers.length);
// console.log(categories.beach.length);
// console.log(categories.fashion.length);
// console.log(categories.wellness.length);
// console.log(categories.cooking.length);
// console.log(categories.social.length);
// console.log(categories.nature.length);

const wildflowers = [
    "blossom",
    "petal",
    "meadow",
    "bloom",
    "daisy",
    "poppy",
    "lavender",
    "sunflower",
    "bluebell",
    "primrose",
    "wildflower",
    "tulip",
    "forget-me-not",
    "rosemary",
    "buttercup",
    "lupine",
    "violet",
    "marigold",
    "snapdragon",
    "aster",
    "hibiscus",
    "geranium",
    "freesia",
    "basil",
    "heather",
    "saffron",
    "chamomile",
    "cosmos",
    "peony",
    "foxglove",
    "coneflower",
    "yarrow",
    "thistle",
    "anemone",
    "columbine",
    "coreopsis",
    "dandelion",
    "edelweiss",
    "goldenrod",
    "honeysuckle",
    "jasmine",
    "larkspur",
    "lilac",
    "magnolia",
    "morning glory",
    "nasturtium",
    "orchid",
    "pansy",
    "petunia",
    "phlox",
    "queen anne's lace",
    "rudbeckia",
    "salvia",
    "sweet pea",
    "trillium",
    "verbena",
    "wisteria",
    "zinnia",
    "clover",
    "chrysanthemum",
    "hydrangea",
    "fennel",
    "angelica",
    "alyssum",
    "begonia",
    "bleeding heart",
    "clematis",
    "cornflower",
    "echinacea",
    "gaillardia",
    "helenium",
    "iris",
    "jacob's ladder",
    "lady's mantle",
    "lobelia",
    "mallow",
    "nemesia",
    "oxeye",
    "pentas",
    "rue",
    "scabiosa",
    "tickseed",
    "veronica",
    "waxflower",
];
const beach = [
    "sand",
    "waves",
    "ocean",
    "sea",
    "beach",
    "seashells",
    "surf",
    "tide",
    "coast",
    "shore",
    "dune",
    "seagull",
    "sunset",
    "driftwood",
    "palm tree",
    "coral",
    "starfish",
    "lagoon",
    "tropical",
    "island",
    "boardwalk",
    "pier",
    "cliffs",
    "bay",
    "harbor",
    "beachcomber",
    "sunshine",
    "breeze",
    "cove",
    "shell",
    "saltwater",
    "marine",
    "beach ball",
    "beach towel",
    "snorkel",
    "scuba",
    "sailboat",
    "catamaran",
    "kayak",
    "paddleboard",
    "swim",
    "lifeguard",
    "tide pool",
    "sandcastle",
    "beach chair",
    "beach umbrella",
    "flip-flops",
    "sunblock",
    "surfboard",
    "jet ski",
    "volleyball",
    "crab",
    "jellyfish",
    "sand dollar",
    "clam",
    "buoy",
    "seawater",
    "reef",
    "fish",
    "pelican",
    "whale",
    "dolphin",
    "manatee",
    "seagrass",
    "seaweed",
    "kelp",
    "wetsuit",
    "splash",
    "tropical fish",
    "hammock",
    "beach hut",
    "bonfire",
    "shellfish",
    "beach cruiser",
    "seaside",
    "sunbathing",
    "beach blanket",
    "margarita",
    "coconut",
    "palm frond",
    "beachcombing",
    "sandbar",
    "shoreline",
    "breakwater",
    "jetty",
    "lighthouse",
    "sand dune",
    "low tide",
    "high tide",
    "beach volleyball",
    "ocean breeze",
    "rip current",
    "surfer",
    "beach party",
    "cabana",
];
const nature = [
    "forest",
    "tree",
    "leaf",
    "river",
    "mountain",
    "hill",
    "valley",
    "creek",
    "stream",
    "lake",
    "pond",
    "meadow",
    "field",
    "flower",
    "grass",
    "wildlife",
    "bird",
    "deer",
    "fox",
    "bear",
    "butterfly",
    "insect",
    "sky",
    "cloud",
    "rain",
    "storm",
    "sunshine",
    "snow",
    "ice",
    "breeze",
    "wind",
    "rock",
    "stone",
    "pebble",
    "canyon",
    "waterfall",
    "cliff",
    "cave",
    "beach",
    "shore",
    "ocean",
    "sea",
    "desert",
    "oasis",
    "savannah",
    "jungle",
    "rainforest",
    "wilderness",
    "habitat",
    "ecosystem",
    "bush",
    "vine",
    "branch",
    "bark",
    "root",
    "moss",
    "lichen",
    "fungus",
    "coral",
    "reef",
    "seaweed",
    "algae",
    "garden",
    "park",
    "trail",
    "path",
    "summit",
    "peak",
    "gorge",
    "plateau",
    "glacier",
    "dune",
    "marsh",
    "swamp",
    "bog",
    "fen",
    "bayou",
    "gulf",
    "archipelago",
    "atoll",
    "delta",
    "estuary",
    "fjord",
];
const social = [
    "post",
    "tweet",
    "like",
    "share",
    "comment",
    "subscribe",
    "follow",
    "hashtag",
    "tag",
    "mention",
    "feed",
    "timeline",
    "story",
    "reel",
    "highlight",
    "filter",
    "emoji",
    "gif",
    "meme",
    "vlog",
    "blog",
    "podcast",
    "livestream",
    "video",
    "photo",
    "selfie",
    "caption",
    "bio",
    "profile",
    "username",
    "handle",
    "trending",
    "viral",
    "influencer",
    "creator",
    "collab",
    "brand",
    "content",
    "engagement",
    "reach",
    "analytics",
    "insights",
    "algorithm",
    "platform",
    "network",
    "community",
    "audience",
    "follower",
    "subscriber",
    "interaction",
    "impression",
    "engage",
    "edit",
    "curate",
    "schedule",
    "upload",
    "download",
    "media",
    "storyboard",
    "thumbnail",
    "footage",
    "clip",
    "montage",
    "transition",
    "filter",
    "frame",
    "overlay",
    "animation",
    "graphic",
    "design",
    "branding",
    "campaign",
    "strategy",
    "marketing",
    "promotion",
    "ad",
    "sponsor",
    "monetize",
];

const cooking = [
    "recipe",
    "ingredient",
    "dish",
    "meal",
    "cuisine",
    "flavor",
    "taste",
    "aroma",
    "spice",
    "herb",
    "seasoning",
    "salt",
    "pepper",
    "sugar",
    "butter",
    "oil",
    "vinegar",
    "garlic",
    "onion",
    "ginger",
    "basil",
    "thyme",
    "rosemary",
    "parsley",
    "cilantro",
    "oregano",
    "dill",
    "chive",
    "bay leaf",
    "sage",
    "cinnamon",
    "nutmeg",
    "clove",
    "cardamom",
    "turmeric",
    "paprika",
    "cumin",
    "coriander",
    "fennel",
    "mustard",
    "saffron",
    "sesame",
    "vanilla",
    "chocolate",
    "cream",
    "milk",
    "cheese",
    "yogurt",
    "egg",
    "flour",
    "bread",
    "pasta",
    "rice",
    "bean",
    "lentil",
    "chickpea",
    "pea",
    "carrot",
    "potato",
    "tomato",
    "pepper",
    "broccoli",
    "spinach",
    "kale",
    "lettuce",
    "cabbage",
    "zucchini",
    "eggplant",
    "mushroom",
    "pumpkin",
    "squash",
    "apple",
    "banana",
    "orange",
    "lemon",
    "lime",
    "grape",
    "berry",
    "strawberry",
    "blueberry",
    "raspberry",
    "blackberry",
    "cherry",
    "melon",
    "watermelon",
    "cantaloupe",
    "honeydew",
    "peach",
    "plum",
    "pear",
    "apricot",
    "nectarine",
    "mango",
    "pineapple",
    "kiwi",
    "papaya",
    "guava",
    "coconut",
    "avocado",
];

const wellness = [
    "wellness",
    "health",
    "fitness",
    "exercise",
    "workout",
    "yoga",
    "meditation",
    "mindfulness",
    "relaxation",
    "stress",
    "anxiety",
    "sleep",
    "rest",
    "nutrition",
    "diet",
    "hydration",
    "water",
    "balance",
    "harmony",
    "energy",
    "vitality",
    "strength",
    "flexibility",
    "endurance",
    "cardio",
    "aerobic",
    "anaerobic",
    "muscle",
    "bone",
    "joint",
    "heart",
    "brain",
    "body",
    "mind",
    "spirit",
    "well-being",
    "mental health",
    "emotional health",
    "physical health",
    "holistic",
    "natural",
    "organic",
    "clean",
    "detox",
    "cleanse",
    "immune",
    "immune system",
    "circulation",
    "digestion",
    "metabolism",
    "weight",
    "loss",
    "gain",
    "management",
    "lifestyle",
    "habit",
    "routine",
    "goal",
    "motivation",
    "discipline",
    "commitment",
    "self-care",
    "self-love",
    "happiness",
    "joy",
    "gratitude",
    "positivity",
    "optimism",
    "resilience",
    "therapy",
    "counseling",
    "support",
    "community",
    "connection",
    "balance",
    "equilibrium",
    "tranquility",
    "serenity",
    "peace",
    "calm",
];

const fashion = [
    "style",
    "trend",
    "outfit",
    "ensemble",
    "wardrobe",
    "apparel",
    "clothing",
    "garment",
    "accessory",
    "jewelry",
    "necklace",
    "bracelet",
    "earrings",
    "ring",
    "watch",
    "hat",
    "cap",
    "scarf",
    "glove",
    "belt",
    "shoe",
    "boot",
    "sandal",
    "heel",
    "sneaker",
    "bag",
    "handbag",
    "purse",
    "clutch",
    "tote",
    "backpack",
    "jacket",
    "coat",
    "blazer",
    "suit",
    "dress",
    "skirt",
    "blouse",
    "shirt",
    "t-shirt",
    "top",
    "tank top",
    "sweater",
    "cardigan",
    "hoodie",
    "jeans",
    "trousers",
    "pants",
    "shorts",
    "legging",
    "tight",
    "sock",
    "underwear",
    "lingerie",
    "swimwear",
    "bikini",
    "one-piece",
    "cover-up",
    "sunglasses",
    "frame",
    "eyewear",
    "lens",
    "design",
    "pattern",
    "print",
    "fabric",
    "material",
    "cotton",
    "wool",
    "silk",
    "satin",
    "linen",
    "denim",
    "leather",
    "suede",
    "lace",
    "velvet",
    "knit",
    "crochet",
    "seam",
    "hem",
    "stitch",
    "button",
    "zipper",
    "closure",
    "fashion show",
    "runway",
    "model",
    "designer",
    "brand",
    "label",
    "collection",
    "season",
    "spring",
    "summer",
    "fall",
    "winter",
    "couture",
    "chic",
    "elegant",
    "casual",
    "formal",
    "vintage",
    "retro",
    "bohemian",
    "minimalist",
    "streetwear",
    "high fashion",
    "ready-to-wear",
];
const categories = [];
categories.wildflowers = wildflowers;
categories.beach = beach;
categories.fashion = fashion;
categories.wellness = wellness;
categories.cooking = cooking;
categories.social = social;
categories.nature = nature;
export default categories;

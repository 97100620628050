import { getSentences } from "../generator";

export default function Button(props) {
    return (
        <div className="flex flex-col justify-center items-center ml-auto mr-auto">
            <h1 className="text-center text-base w-3/12">
                Click Generate when ready
            </h1>
            <button
                className="bg-purple-400 hover:bg-purple-500 text-white font-bold py-2 px-4 rounded-full my-2 items-center"
                onClick={() => getSentences(props.selectedValue, 4)}
            >
                Generate
            </button>
        </div>
    );
}
